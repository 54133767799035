import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import queryString from 'query-string';

// Components
import Layout from '../../components/Layout';

// Images
import Gallery from '../../img/gallery.gif';

// Language
import { getPackOf } from '../../lang';
const LANG = getPackOf('zh');

const PRODUCT_ALL = 'All';

const Product = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { glob: "**/src/markdown/products/**.md" } }) {
        edges {
          node {
            frontmatter {
              disable
              title
              subTitle
              productImg {
                img {
                  publicURL
                }
              }
              urlRoute
              category
            }
          }
        }
        group(field: frontmatter___category) {
          fieldValue
        }
      }
    }
  `);

  let [query, setQuery] = useState(PRODUCT_ALL);

  useEffect(() => {
    data.allMarkdownRemark.group.forEach(({ fieldValue }) => {
      if (queryString.parse(props.location.search).category === fieldValue) {
        setQuery(fieldValue);
        return;
      }
    });
  }, []);

  const renderTagList = () => {
    const group = [PRODUCT_ALL, ...data.allMarkdownRemark.group.map(({ fieldValue }) => fieldValue)];
    return group.map((fieldValue) => (
      <ul className="portfolio_filter m-0">
        <li key={fieldValue} style={{ cursor: 'pointer' }}>
          <a onClick={() => setQuery(fieldValue)} className={fieldValue === query ? 'active' : null}>
            {LANG[fieldValue]}
          </a>
        </li>
      </ul>
    ));
  };

  const renderProducts = () => {
    const filteredProductEdges = data.allMarkdownRemark.edges.filter(
      (edge) => (edge.node.frontmatter.category === query || PRODUCT_ALL === query) && !edge.node.frontmatter.disable
    );

    return filteredProductEdges.map(({ node: { frontmatter: { title, subTitle, productImg, urlRoute } } }) => (
      <div className="col-6 col-md-4 col-lg-3 mb-3 spc" key={urlRoute}>
        <Link to={`/product/${urlRoute}`}>
          <div className="card text-secondary">
            <div
              className="hover-zoom rounded-top"
              style={{ backgroundImage: `url(${productImg && productImg[0].img.publicURL})` }}
            >
              <img src={Gallery} className="card-img-top" />
            </div>
            <div className="card-body">
              <h5 className="card-title text_limit1" style={{ color: 'rgba(108,117,125)' }}>
                {title}
              </h5>
              <p className="card-text text_limit2">{subTitle}</p>
              <span className="btn-sm btn-primary">{LANG.了解產品}</span>
            </div>
          </div>
        </Link>
      </div>
    ));
  };

  return (
    <Layout>
      <section id="page_bn" className="pb-1" style={{ backgroundColor: '#efefef', marginTop: '-16px' }}>
        <div className="container">
          <div id="breadcrumb" className="">
            <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
              <div className="col-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb small text-white pt-3 pb-2 bg-js">
                    <li className="breadcrumb-item">
                      <Link to="/" style={{ color: '#fff' }}>
                        {LANG.首頁}
                      </Link>
                    </li>
                    <li className="breadcrumb-item">{LANG.太陽能支架}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="case" className="my-3">
        <div className="container ">
          <div className="row">
            <div className="col-12 text-left">
              <p className="h3">
                <strong>{LANG.太陽能支架應用方案}</strong>
              </p>
            </div>
            <div id="" className="col-12">
              <hr />
            </div>
          </div>
        </div>
      </section>
      <section className="container mb-4">
        <div className="portfolio-div">
          <div className="portfolio">
            <div className="row col-12 my-4">
              <div className="categories-grid wow fadeInLeft">
                <nav className="categories text-center">{renderTagList()}</nav>
              </div>
            </div>
            <div className="portfolio_container row">{renderProducts()}</div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Product;
